.ChatMenu {
    position: absolute;
    max-height: calc(100% - 45px);
    width: calc(100% - 108px);
    left: 48px;
    bottom: 34px;
    overflow-y: auto;
}

.ChatMenuIcon {
    margin-right: 10px;
}

.ant-menu-item {
    height: 25px!important;
    line-height: 25px!important;
    /* padding-left: 1px!important;
    padding-right: 1px!important; */
}

/* .ant-menu-submenu-title {
    padding-left: 1px!important;
    padding-right: 1px!important;
} */
