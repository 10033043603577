.ChatInput {
    width: calc(100% - 46px);
    display: flex;
    margin-left: 2px;
}

.ChatInputFormDiv {
    width: calc(100% - 51px);
    display: flex;
    margin-left: 2px;
}

.ChatInputForm {
    width: calc(100% - 6px);
    display: flex;
}

.ChatInputDisabled {
    /* border-color: #767676; */
    pointer-events: none;
    display: none;
}

.ChatInputFormBtn {
    margin-left: auto;
}

.ChatFormSpan {
    /* margin-left: 5px;
    margin-right: 5px; */
    line-height: 2.5;
}
