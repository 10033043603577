.ContactItemSelected {
    border-top-left-radius: var(--main-radius);
    border-top-right-radius: var(--main-radius);
    border-bottom-right-radius: var(--main-radius);
    border-bottom-left-radius: 1px;
}

.lastMessage {
    font-size: 11px;
    margin: 0;
    color: #666;
}
