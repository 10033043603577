:root {
  --main-radius: 10px;
}
/*
@media (prefers-color-scheme: light) {
  body, input[type=text], div[contenteditable=true] {
      background-color: var(--main-bg-color-light);
      color: var(--main-color-light);
  }

  input[type=text], div[contenteditable=true] {
    border-color: var(--main-border-color-light);
  }

  .User1 {
    background-color: rgb(186 186 200);
  }

  .abtn {
    background: #b4b4b4;
  }

  button {
    color: var(--main-color-light);
  }

  .App-link, a {
    color: var(--main-color-light)!important;
  }
}

@media (prefers-color-scheme: dark) {
  body, input[type=text], div[contenteditable=true] {
      background-color: var(--main-bg-color-dark);
      color: var(--main-color-dark);
  }

  input[type=text], div[contenteditable=true] {
    border-color: var(--main-border-color-dark);
  }

  .User1 {
    background-color: rgb(28, 28, 37);
  }

  .abtn {
    background: #333;
  }

  button {
    color: var(--main-color-dark);
  }

  .App-link, a {
    color: var(--main-color-dark)!important;
  }
} */

.App, #root, body, html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: 'Roboto Condensed'!important;
}

.AppHeader {
  display: flex;
  padding-left: 10px;
}

.AppHeaderLink {
  padding-right: 20px;
}

.InfoComponent {
  font-family: 'Roboto Condensed';
  height: 100%;
  overflow: auto;
  padding-top: 2px;
}

.fullh {
  height: 100%;
}

.tiledBackground {
  background-image: url(assets/wave.png);
  background-size: 50px;
}

.btn {
  height: 40px;
  background: transparent;
}

.btn-image {
  height: 30px;
}

.toprigth {
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
}

input[type=text], div[contenteditable=true] {
  font-family: 'Roboto Condensed';
  padding-left: 10px;
}

input[type=text], div[contenteditable=true], .th-border {
  border-width: 2px;
  border-style: double;
}

input:focus, div[contenteditable=true]:focus {
  outline: none;
}

div[contenteditable=true] {
  overflow: auto;
}

.th-border {
  border-top-left-radius: var(--main-radius);
  border-top-right-radius: var(--main-radius);
  border-color: rgb(80, 80, 80);
  border-right: 2px double rgb(80, 80, 80)!important;
}

.input-large {
  height: 40px;
  font-size: large;
  border-radius: var(--main-radius);
  width: calc(100% - 44px);
}

.input-medium {
  height: 30px;
  font-size: 16px;
  border-radius: var(--main-radius);
}

.InputLink {
  font-size: large;
  border-radius: var(--main-radius);
  height: 30px;
  width: 30px;
}

form input {
  vertical-align: middle;
}

form button {
  vertical-align: middle;
}

button {
  height: 40px;
  background-color: transparent;
  border-width: 0px;
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
  margin: 0;
}

button:focus {
  outline: none;
}

p {
  margin-top: 2px;
  margin-bottom: 2px;
}

p, div {
  overflow-wrap: break-word;
}

.paddlr {
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
}
