.ConversationName {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0px;
}

.ActionButton {
    font-size: 20px;
}

.logo-image {
    width: 100%;
    height: 100%;
}

.logo-text {
    text-align: center;
    color: #eee;
}

.logo-div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to top, rgba(100,100,100,0.5) 50%, rgba(255,255,255,0.7) 50%);
    -webkit-mask-image: -webkit-gradient( radial, 50% 50%, 0, 50% 50%, 230, from(#fff), to(rgba(0,0,0,0)));
}

.logo-inner-div {
    display: block;
    opacity: 0.9;
}

.LandingPageContainer {
    width: 100%;
    height: 100%;
}

.SystemMenuBtn {
    position: absolute;
    bottom: 0px;
    left: 6px;
}
