.ChatModal {
    height: calc(100% - 180px)!important;
    width: calc(100% - 96px)!important;
    padding-bottom: 0px;
}

.ChatModalContent {
    height: 100%;
    overflow: auto;
}

.ant-modal-content {
    width: 100%!important;
    height: 100%!important;
}

.ant-modal-body {
    height: calc(100% - 45px)!important;
}
