.ChatBubbleFooter {
    display: flex;
    align-items: center;
}

.ChatBubbleFooter > p {
    padding-right: 5px;
}

.ChatBubbleFooterRight {
    margin-left: auto;
}

.ChatBubbleFooterLeft {
    margin-right: auto;
}

.ChatBubbleFooterContainer {
    display: flex;
}

.ChatBubbleFooterMenuLeft {
    margin-right: auto;
}

.ChatBubbleFooterMenuRight {
    margin-left: auto;
}

.ChatMessageIcon {
    font-size: 20px;

}
